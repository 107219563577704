import React from 'react';
import Button from '../Button';
import { joinContent } from '../../content/index';

function Apply() {
  const { header, description, cta } = joinContent.apply;

  return (
    <div
      data-testid="apply-container"
      className="pt-7 pb-9 md:py-11 xl:py-11 flex flex-col justify-center"
    >
      <div className="mx-auto text-lg md:text-2xl md:font-bold font-semibold text-center">
        <h1>{header}</h1>
      </div>
      <p className="mx-auto md:text-base text-center text-sm mt-3 md:mt-2 mt-4 md:mb-7 mb-6 w-8/12 lg:w-4/12">
        {description}
      </p>
      <div data-testid="apply-email-container" className="mx-auto">
        <Button
          className="md:hidden w-12.5"
          text={cta.mobile}
          to="/careers"
          variant="transparent"
        />
        <Button
          className="md:block hidden w-12.5"
          text={cta.desktop}
          to="/careers"
          variant="transparent"
        />
      </div>
    </div>
  );
}

export default Apply;
